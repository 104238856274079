import { AxiosResponse } from "axios";
import backendApi from "./backendApi";

// Response type for each receipt status
export interface ReceiptStatusItem {
  result: boolean;
  isValid: boolean;
  isDuplicate: boolean;
  isStampDelivered: boolean;
  message: string | null;
  receiptName: string;
}

// Request payload type
interface ReceiptStatusPayload {
  programUuid: string;
  shopperUuid: string;
  date?: string;
}

/**
 * Converts local date to UTC date string (YYYY-MM-DD format)
 * @param date Optional date string. If not provided, uses current date
 * @returns UTC date string in YYYY-MM-DD format
 */
const getUTCDateString = (date?: string): string => {
  // Create date object from input or current date
  const localDate = date ? new Date(date) : new Date();

  // Create UTC date string by using UTC methods
  const year = localDate.getUTCFullYear();
  const month = String(localDate.getUTCMonth() + 1).padStart(2, "0"); // +1 because months are 0-indexed
  const day = String(localDate.getUTCDate()).padStart(2, "0");

  // Return in YYYY-MM-DD format
  return `${year}-${month}-${day}`;
};

/**
 * Gets receipt status for a program and shopper
 * @param programUuid Program UUID
 * @param shopperUuid Shopper UUID
 * @param date Optional local date string (YYYY-MM-DD)
 * @returns Array of receipt status items
 */
export const getReceiptStatus = async (
  programUuid: string,
  shopperUuid: string,
  date?: string,
): Promise<ReceiptStatusItem[]> => {
  const payload: ReceiptStatusPayload = {
    programUuid,
    shopperUuid,
  };

  // Only add date if provided, and convert to UTC
  if (date) {
    payload.date = getUTCDateString(date);
  }

  const response = await backendApi.post<
    ReceiptStatusPayload,
    AxiosResponse<ReceiptStatusItem[]>
  >("v1/receipt/status", payload);

  return response.data;
};
