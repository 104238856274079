import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import TabsAlikeNav from "../../Components/tabs-alike-nav/tabs-alike-nav";
import MembershipCard from "../../Components/membership-card/membership-card";
import VoucherCard from "../../Components/voucher-card/voucher-card";
import Null from "../../Components/null/null";
import { animate } from "motion";
import { motion } from "framer-motion";

import "../../shared/imports/_variables.css";
import "../../shared/imports/hardcoded-components/timer.css";
import "../../shared/imports/hardcoded-components/location.css";
import "../../shared/imports/hardcoded-components/deal.css";
import "../../Components/pwa-header/pwa-header.css";
import "./home.css";
import { AppRoute } from "../../shared/const";
import PWAHeader from "../../Components/pwa-header/pwa-header";
import PWANav from "../../Components/pwa-nav/pwa-nav";
import Container from "../../Components/container/container";
import openNotification from "../../Components/Antd/Notification/Notification";
import getLoggedInUser from "../../hooks/useLoggedInUser";
import { GetVoucher, GetVoucherResponse } from "../../services/api/getVouchers";
import getSelectedProgram from "../../hooks/useSelectedProgram";
import PopUpModal from "../../Components/Antd/Modal/Modal";
import useGetVerified from "../../hooks/getVerified";
import { selfStamp } from "../../services/api/selfStamp";
import { Modal, Button } from "antd";
import Camera from "../../assets/icons/camera.png";
import getLocations from "../../services/api/getLocation";

// Add interface for Location
interface Location {
  id: number;
  memberReceiptScanEnabled: boolean; // Add this field
  // ... other fields
}

function Home() {
  const [selectedTab, setSelectedTab] = useState("All");
  const [showPopup, setShowPopup] = useState(false);
  const [showReceiptScanningPopup, setShowReceiptScanningPopup] =
    useState(false); // New state variable
  const loggedInUser = getLoggedInUser();
  const selectedProgram = getSelectedProgram();
  const [isLoading, setIsLoading] = useState(false);
  const [vouchers, setVouchers] = useState<GetVoucherResponse>();
  const [voucherCount, setVoucherCount] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalMessage2, setModalMessage2] = useState("");

  const getVerified = useGetVerified();

  useEffect(() => {
    getVerified();
  });

  const handleCloseModal = () => {
    setModalVisible(false);
    handleGetVoucher();
  };

  useEffect(() => {
    animate(".home__card", {
      // Keyframes (array or single value).
      opacity: [0, 1],
    });
  }, []);

  const handleGetVoucher = useCallback(async () => {
    setIsLoading(true);
    setVouchers([]);

    try {
      const response = await GetVoucher(loggedInUser?.uuid ?? "");
      setVouchers(response);

      let totalVoucherCount = response.reduce((total, voucher) => {
        if (
          voucher.Program.uuid === selectedProgram?.uuid &&
          voucher.availableRedemptionCount !== 0
        ) {
          return total + voucher.availableRedemptionCount;
        } else {
          return total;
        }
      }, 0);
      setVoucherCount(totalVoucherCount);

      // Add this block
      const locationID = localStorage.getItem("locationID");
      if (locationID) {
        response.forEach(async (voucher) => {
          if (voucher.memberSelfStamping) {
            try {
              const apiResponse = await selfStamp({
                shopperUuid: loggedInUser?.uuid ?? "",
                programUuid: voucher.Program.uuid,
                locationId: Number(locationID),
              });
              console.log(apiResponse); // Log the response
              localStorage.removeItem("locationID");
              setModalMessage("Success");
              if (apiResponse.success) {
                setModalMessage("Success");
              } else {
                setModalMessage(
                  `You have already checked-in within the last 24 hours.`,
                );
                setModalMessage2(
                  `Next check-in available in ${apiResponse.waitingTime}`,
                );
              }
              setModalVisible(true);
            } catch (err) {
              console.error(
                `Failed to call API for voucher ${voucher.id}`,
                err,
              );
              setModalMessage("An unexpected error occurred.");
              setModalVisible(true);
            }
          }
        });
      } else {
        console.log("No location ID");
        localStorage.removeItem("locationID");
      }
    } catch (err) {
      console.error(err);
      openNotification("error", `Fail to load vouchers`, null);
    } finally {
      setIsLoading(false);
      localStorage.removeItem("locationID");
    }
  }, [loggedInUser, selectedProgram]);

  useEffect(() => {
    handleGetVoucher();
  }, [handleGetVoucher]);

  useEffect(() => {
    if (localStorage.getItem("newMembership") === "true") {
      setShowPopup(true);
    }
  }, []);

  const handleCloseShowPopup = () => {
    setShowPopup(false);
    if (
      sortedVouchersWithRemainder &&
      sortedVouchersWithRemainder.some(
        (voucher) => voucher.memberReceiptScanning,
      )
    ) {
      setShowReceiptScanningPopup(true);
      localStorage.removeItem("newMembership");
    } else {
      localStorage.removeItem("newMembership");
      localStorage.removeItem("ScanMembershipUuid");
      localStorage.removeItem("ScanLocationID");
    }
  };

  const navigate = useNavigate();

  const handleOpenReceiptScanning = () => {
    const url = `/CameraApp/${localStorage.getItem("ScanMembershipUuid")}/${localStorage.getItem("ScanLocationID")}`;
    navigate(url);
  };

  const handleCloseReceiptScanningPopup = () => {
    setShowReceiptScanningPopup(false);
    localStorage.removeItem("ScanMembershipUuid");
    localStorage.removeItem("ScanLocationID");
  };

  let sortedVouchers = (vouchers ?? []).sort((a, b) => {
    if (a.unlockVisitThreshold !== 0 && b.unlockVisitThreshold === 0) {
      return -1;
    }
    if (a.unlockVisitThreshold === 0 && b.unlockVisitThreshold !== 0) {
      return 1;
    }
    return 0;
  });
  const today = new Date();

  if (selectedTab === "New") {
    sortedVouchers = sortedVouchers
      .filter((voucher) => {
        const startDate = new Date(voucher.startDate);
        const diffInDays = Math.ceil(
          (today.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24),
        );
        return diffInDays <= 7;
      })
      .sort(
        (a, b) =>
          new Date(b.startDate).getTime() - new Date(a.startDate).getTime(),
      );
  } else if (selectedTab === "Expires Soon") {
    sortedVouchers = sortedVouchers
      .filter((voucher) => {
        const expiryDate = new Date(voucher.expiryDate);
        const diffInDays = Math.ceil(
          (expiryDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24),
        );
        return diffInDays <= 7;
      })
      .sort(
        (a, b) =>
          new Date(a.expiryDate).getTime() - new Date(b.expiryDate).getTime(),
      );
  }
  console.log(sortedVouchers);

  sortedVouchers = sortedVouchers.filter(
    (voucher) => voucher.Program.uuid === selectedProgram?.uuid,
  );

  const sortedVouchersWithRemainder = sortedVouchers.map((voucher) => ({
    ...voucher,
    remainder: voucher.isReferralVoucher
      ? voucher.recordedReferrals % voucher.referralRewardThreshold
      : voucher.recordedVisits % voucher.unlockVisitThreshold,
  }));
  sortedVouchersWithRemainder.sort((a, b) => b.remainder - a.remainder);

  // Add state for locations
  const [locations, setLocations] = useState<Location[]>([]);

  // Add function to fetch and check locations
  const fetchAndCheckLocation = useCallback(async () => {
    console.log("fetchAndCheckLocation called with:", {
      programUuid: selectedProgram?.uuid,
      hasLocationId: !!localStorage.getItem("ScanLocationID"),
      scanLocationId: localStorage.getItem("ScanLocationID"),
    });

    if (selectedProgram?.uuid && localStorage.getItem("ScanLocationID")) {
      try {
        const locationData = await getLocations(selectedProgram.uuid);
        setLocations(locationData);

        // Find matching location
        const scanLocationId = Number(localStorage.getItem("ScanLocationID"));

        // Debug logs
        console.log("Location check details:", {
          scanLocationId,
          locationDataLength: locationData.length,
          locationIds: locationData.map((loc: Location) => loc.id),
          rawLocationId: localStorage.getItem("ScanLocationID"),
        });

        const matchingLocation = locationData.find(
          (loc: Location) => loc.id === scanLocationId,
        );

        console.log("Matching location result:", {
          found: !!matchingLocation,
          matchingLocation,
          scanningEnabled: matchingLocation?.memberReceiptScanEnabled,
        });

        // First check if we found a matching location
        if (!matchingLocation) {
          console.log("Location not found. Available locations:", locationData);
          openNotification(
            "error",
            "Invalid QR code - Location not found",
            null,
          );
          return; // Exit without removing ScanLocationID
        }

        // Then check if scanning is enabled
        if (matchingLocation.memberReceiptScanEnabled) {
          setShowReceiptScanningPopup(true);
        } else {
          // console.log(
          //   "Location found but scanning not enabled:",
          //   matchingLocation,
          // );
          // openNotification(
          //   "error",
          //   "Receipt scanning is not enabled for this location",
          //   null,
          // );
          // Only remove IDs if scanning is explicitly disabled
          localStorage.removeItem("ScanLocationID");
          localStorage.removeItem("ScanMembershipUuid");
        }
      } catch (err) {
        console.error("Failed to fetch locations:", err);
        openNotification("error", "Failed to load location information", null);
      }
    }
  }, [selectedProgram?.uuid]);

  // Update the useEffect that checks for receipt scanning
  useEffect(() => {
    if (
      sortedVouchersWithRemainder &&
      sortedVouchersWithRemainder.some(
        (voucher) => voucher.memberReceiptScanning,
      ) &&
      localStorage.getItem("ScanMembershipUuid") &&
      !localStorage.getItem("newMembership") &&
      localStorage.getItem("ScanLocationID")
    ) {
      fetchAndCheckLocation();
    }
  }, [vouchers, fetchAndCheckLocation]);

  useEffect(() => {
    console.log("Initial vouchers:", vouchers);
    console.log("Selected program:", selectedProgram);
  }, [vouchers, selectedProgram]);

  return (
    <>
      <Modal
        title="Check-In Status"
        open={modalVisible}
        onOk={() => handleCloseModal()}
        onCancel={() => handleCloseModal()}
      >
        <p>{modalMessage}</p>
        <p>{modalMessage2}</p>
      </Modal>
      <div className="pwa-page">
        <PWAHeader />
        {showPopup && (
          <PopUpModal
            brandName={selectedProgram?.name}
            logo={selectedProgram?.brandLogo}
            onClose={handleCloseShowPopup} // Add onClose handler
          />
        )}
        {showReceiptScanningPopup && (
          <Modal
            title="Receipt Scanning"
            visible={showReceiptScanningPopup}
            onCancel={handleCloseReceiptScanningPopup}
            footer={null} // Remove default footer
            closable={false}
            width={"80%"}
          >
            <p>． If you have a receipt, click Scan Receipt for rewards.</p>
            <p>． Otherwise, click Skip to access your membership.</p>
            <img
              src={Camera}
              alt="Camera"
              style={{ width: "50%", height: "auto" }}
            />
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <Button
                key="scan"
                type="primary"
                className="home_scan-button"
                onClick={handleOpenReceiptScanning}
              >
                Scan Receipt
              </Button>
              <Button
                key="skip"
                className="home_skip-button"
                onClick={handleCloseReceiptScanningPopup}
              >
                Skip
              </Button>
            </div>
          </Modal>
        )}
        <Container className="home">
          <div className="home__card">
            <MembershipCard
              backgroundColor={selectedProgram?.brandColor}
              voucherCount={voucherCount}
              brandPattern={selectedProgram?.brandPattern || "None"}
            />
          </div>
          <div className="home__filters">
            <TabsAlikeNav
              brandColor={selectedProgram?.brandColor || ""}
              onTabSelect={setSelectedTab}
            />
          </div>
          <div className="home__list">
            {sortedVouchersWithRemainder.length > 0 ? (
              sortedVouchersWithRemainder.map((voucher, index) => {
                console.log("Processing voucher:", voucher);

                // For stamp cards and referral vouchers
                if (
                  voucher.unlockVisitThreshold !== 0 ||
                  voucher.isReferralVoucher
                ) {
                  const vouchersToRender = [];

                  // Calculate threshold and progress based on voucher type
                  const threshold = voucher.isReferralVoucher
                    ? voucher.referralRewardThreshold
                    : voucher.unlockVisitThreshold;

                  const progress = voucher.isReferralVoucher
                    ? voucher.recordedReferrals % threshold
                    : voucher.recordedVisits % threshold;

                  // Modify campaign name for referral vouchers
                  const campaignName = voucher.isReferralVoucher
                    ? `${voucher.campaignName} (Referral Reward)`
                    : voucher.campaignName;

                  // Show stamp card in these cases:
                  // 1. New card (recordedVisits = 0)
                  // 2. Card with progress (progress > 0)
                  // 3. Completed card starting new cycle
                  if (
                    voucher.recordedVisits === 0 || // Add this condition for new cards
                    progress > 0 ||
                    (progress === 0 && voucher.recordedVisits > 0)
                  ) {
                    vouchersToRender.push(
                      <motion.div
                        key={`${voucher.id}-progress`}
                        animate={{ opacity: [0, 1] }}
                        transition={{
                          duration: 0.8,
                          delay: index * 0.1,
                          ease: "easeInOut",
                        }}
                        className="home__item"
                      >
                        <VoucherCard
                          key={`${voucher.id}-progress`}
                          brandName={voucher.Program.name}
                          startDate={voucher.startDate}
                          expriyDate={voucher.expiryDate}
                          discount={voucher.valueDescription}
                          product={campaignName}
                          location={voucher.Program.physicalAddress}
                          backgroundColor={voucher.Program.brandColor}
                          voucherImage={voucher.imageId}
                          brandImage={voucher.Program.imageURL}
                          textColor="#000000"
                          unlockVisitThreshold={threshold}
                          recordedVisits={progress}
                          code={voucher.code}
                          programUuid={voucher.Program.uuid}
                        />
                      </motion.div>,
                    );
                  }

                  // Add completed vouchers if any
                  if (voucher.availableRedemptionCount > 0) {
                    for (let i = 0; i < voucher.availableRedemptionCount; i++) {
                      vouchersToRender.push(
                        <motion.div
                          key={`${voucher.id}-complete-${i}`}
                          animate={{ opacity: [0, 1] }}
                          transition={{
                            duration: 0.8,
                            delay: (index + i + 1) * 0.1,
                            ease: "easeInOut",
                          }}
                          className="home__item"
                        >
                          <VoucherCard
                            key={`${voucher.id}-complete-${i}`}
                            brandName={voucher.Program.name}
                            startDate={voucher.startDate}
                            expriyDate={voucher.expiryDate}
                            discount={voucher.valueDescription}
                            product={campaignName}
                            location={voucher.Program.physicalAddress}
                            backgroundColor={voucher.Program.brandColor}
                            voucherImage={voucher.imageId}
                            brandImage={voucher.Program.imageURL}
                            textColor="#000000"
                            unlockVisitThreshold={0}
                            recordedVisits={0}
                            code={voucher.code}
                            programUuid={voucher.Program.uuid}
                          />
                        </motion.div>,
                      );
                    }
                  }

                  return vouchersToRender;
                }
                // For regular vouchers (unlockVisitThreshold = 0 and not referral)
                else {
                  return (
                    <motion.div
                      key={`${voucher.id}-regular`}
                      animate={{ opacity: [0, 1] }}
                      transition={{
                        duration: 0.8,
                        delay: index * 0.1,
                        ease: "easeInOut",
                      }}
                      className="home__item"
                    >
                      <VoucherCard
                        key={`${voucher.id}-regular`}
                        brandName={voucher.Program.name}
                        startDate={voucher.startDate}
                        expriyDate={voucher.expiryDate}
                        discount={voucher.valueDescription}
                        product={voucher.campaignName}
                        location={voucher.Program.physicalAddress}
                        backgroundColor={voucher.Program.brandColor}
                        voucherImage={voucher.imageId}
                        brandImage={voucher.Program.imageURL}
                        textColor="#000000"
                        unlockVisitThreshold={0}
                        recordedVisits={0}
                        code={voucher.code}
                        programUuid={voucher.Program.uuid}
                      />
                    </motion.div>
                  );
                }
              })
            ) : (
              <Null>No Vouchers</Null>
            )}
          </div>
        </Container>

        <PWANav />
      </div>
    </>
  );
}

export default Home;
