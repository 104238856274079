import axiosInstance from "./axiosIntercept";

/**
 * @param {ArrayBuffer} buffer
 * @returns {string}
 */
function hex(buffer) {
  var hexCodes = [];
  var view = new DataView(buffer);
  for (var i = 0; i < view.byteLength; i += 4) {
    var value = view.getUint32(i);
    var stringValue = value.toString(16);
    var padding = "00000000";
    var paddedValue = (padding + stringValue).slice(-padding.length);
    hexCodes.push(paddedValue);
  }
  return hexCodes.join("");
}

export const dataUrlToArrayBuffer = (dataUrl) => {
  const base64 = dataUrl.split(",")[1];
  const binaryString = window.atob(base64);
  const bytes = new Uint8Array(binaryString.length);
  return bytes.map((byte, i) => binaryString.charCodeAt(i));
};

/**
 * @param {ArrayBuffer} buffer
 * @param {string} shopperUuid
 * @returns {Promise<string>} sha
 */
export const uploadReceiptFile = async (
  buffer,
  membershipUuid,
  shopperUuid,
  locationId,
) => {
  const sha = hex(await crypto.subtle.digest("SHA-256", buffer));

  // Generate current date and time in ddmmyyhhmmss format
  const now = new Date();
  const dd = String(now.getDate()).padStart(2, "0");
  const mm = String(now.getMonth() + 1).padStart(2, "0"); // January is 0!
  const yy = String(now.getFullYear()).slice(-2);
  const hh = String(now.getHours()).padStart(2, "0");
  const min = String(now.getMinutes()).padStart(2, "0");
  const ss = String(now.getSeconds()).padStart(2, "0");
  const timestamp = `${dd}${mm}${yy}${hh}${min}${ss}`;

  // Construct the file name
  const fileName = `${membershipUuid}_${shopperUuid}_${timestamp}.jpg`;
  console.log("Uploading receipt image:", fileName);

  const formData = new FormData();
  formData.append("file", new Blob([buffer], { type: "image/jpeg" }));
  const receiptSHA = sha;
  try {
    const response = await axiosInstance.post(
      `${process.env.REACT_APP_API_URL}v3/receipt/upload`,
      formData,
      {
        params: {
          key: `${sha}.jpg`,
          membershipUuid,
          shopperUuid,
          receiptSHA,
          locationId,
        },
        withCredentials: true,
        headers: {
          "api-key": process.env.REACT_APP_API_KEY,
          "content-type": "multipart/form-data",
        },
        maxBodyLength: 6 * 1024 * 1024, // Set max body length to 6 MB
      },
    );
    console.log(
      `Receipt upload sent: key ${timestamp}  membershipUuid ${membershipUuid} shopperUuid ${shopperUuid} sha ${sha} locationId ${locationId}`,
    );
    console.log("Receipt uploaded successfully:", response.data);

    return sha;
  } catch (error) {
    console.error("Error uploading receipt:", error);
  }
};
